import type { FC } from 'react';
import React, { memo, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { LinkItem } from '@atlaskit/side-navigation';
import Tooltip from '@atlaskit/tooltip';

import { CreateBlogButton } from '@confluence/contextual-create';
import { usePageSpaceKey } from '@confluence/page-context';
import { SPACE_BLOGS } from '@confluence/named-routes';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useSessionData } from '@confluence/session-data';
import {
	preloadSpaceBlogs,
	preloadSpaceBlogsAppearance,
} from '@confluence/space-blogs/entry-points/preloadSpaceBlogs';

import { isRoute, cssFn } from './SpaceLinks';

const BlogsIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-BlogsIcon" */ './img/BlogsIcon')).BlogsIcon,
});

const i18n = defineMessages({
	blog: {
		id: 'side-navigation.blog.word',
		defaultMessage: 'Blog',
		description: 'Button label for taking the user to the blog',
	},
	back: {
		id: 'side-navigation.blog.back',
		defaultMessage: 'Go Back',
		description: 'Button label for taking the user back to the default page tree',
	},
	blogsTooltip: {
		id: 'side-navigation.space-navigation.blogs.tooltip',
		defaultMessage: 'View all blogs in this space',
		description: 'Tooltip message on hover of Blogs space link',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomItem = styled.li({
	position: 'relative',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > button': {
		height: '100%',
		width: '100%',
		border: 'none',
		fontWeight: 'initial',
		'&:focus': {
			background: 'transparent',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-right-arrow]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		display: 'none !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionButtonWrapper = styled.div({
	position: 'absolute',
	right: token('space.050', '4px'),
	top: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		width: token('space.300', '24px'),
		height: token('space.300', '24px'),
		marginTop: token('space.025', '2px'),
	},
});

type BlogNavigationProps = {
	isSelected: boolean;
	onClick?: () => void;
};

const BlogNavigationComponent: FC<BlogNavigationProps> = ({ isSelected, onClick }) => {
	const [stateSpaceKey] = usePageSpaceKey();
	const { isLoggedIn } = useSessionData();
	// @ts-ignore FIXME: `stateSpaceKey` can be `undefined` here, and needs proper handling
	const spaceKey: string = stateSpaceKey;

	const handlePreloadSpaceBlogs = useCallback(() => {
		if (!isSelected) {
			void preloadSpaceBlogs({ spaceKey });
			void preloadSpaceBlogsAppearance({ spaceKey, isLoggedIn });
		}
	}, [spaceKey, isLoggedIn, isSelected]);

	return (
		<Tooltip position="top" content={<FormattedMessage {...i18n.blogsTooltip} />}>
			{(tooltipProps) => (
				<CustomItem onMouseEnter={handlePreloadSpaceBlogs}>
					<LinkItem
						// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
						cssFn={cssFn}
						key="blogs-view"
						data-testId="space-navigation-blog-link--item"
						iconBefore={
							<BlogsIcon isSelected={isRoute(location?.pathname, SPACE_BLOGS) || isSelected} />
						}
						isSelected={isRoute(location?.pathname, SPACE_BLOGS) || isSelected}
						href={SPACE_BLOGS.toUrl({ spaceKey })}
						{...tooltipProps}
						onClick={onClick}
					>
						<FormattedMessage
							id="side-navigation.container.blogs"
							defaultMessage="Blogs"
							description="all blogs page view button"
						/>
						<ActionButtonWrapper>
							<CreateBlogButton spaceKey={spaceKey} />
						</ActionButtonWrapper>
					</LinkItem>
				</CustomItem>
			)}
		</Tooltip>
	);
};

export const BlogNavigation = memo(BlogNavigationComponent);
